.paperAllocationChart {
    height: 400px;
    border-radius: 20px;
    position: relative
}

.paperAllocationChart h5 {
    position: absolute;
    top: 40px;
    left: 20px
}

.allocationChartContent{
    margin: 0 auto;
    width: 370px;
    height: 370px ;
    max-width : 100%
}

.chartLoaderBox{
    margin-top : 40%;
    display : flex;
    justify-content : center;
    font-size: 20px;
    background-color: #cddc39;
    color: #1f1f1f;
    font-weight: bold;
    padding: 10px;
    border-radius: 5px;
}
.chartPreloader{
    width: 130px !important;
    height: 130px !important;
}

.chartDescription{
    bottom: 0;
    position: absolute;
    font-size: 14px !important;
    padding: 15px;
    color: #a29393;
    width: 300px;
}


/*XS*/
@media (min-width: 0px) {
    .paperAllocationChart {
        width: 100%;
    }
}

/*SM*/
@media (min-width: 600px) {

}

/*MD*/
@media (min-width: 900px) {
}

/*LG*/
@media (min-width: 1200px) {
    .paperAllocationChart {
        width: 550px;
    }
}

/*XL*/
@media (min-width: 1536px) {
}