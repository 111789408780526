.container {
    margin-top: 50px;
    margin-bottom: 50px ;
}
.newsMainTitle{
    margin-top: 50px;
    margin-bottom: 20px !important;
    color: #fff;
    text-align: center;
}
.newsTypeBox{
    display: flex;
    justify-content: space-between;
    flex-wrap : wrap;
    gap : 30px ;
    margin-bottom: 40px
}
.newsTypeTitle{
    text-align: center;
    margin-bottom: 10px !important;
}
.newsTypeAvatar{
    width: 100px !important;
    height: 100px !important;
}
.paperNewsSection{
    position: relative;
    border-radius: 20px;
    margin-bottom: 30px;
}
.newsContentSection{
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.newsContentSection a {
    text-decoration: none;
    color: black
}
.newsContentTitle{
    font-weight: bold;
    background-color: #e0f64b;
    color: black;
    padding: 3px;
    border-radius: 5px;
    text-align: center;
}
.newsImageBox{
    overflow: hidden;
    width: 100%;
    border-radius: 10px;
    border: 2px solid #e0f64b;
    margin: 0 auto;
}
.newsImageBox img{
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 10px;
}
.newsDescription{
    margin-bottom: 40px;
}
.newsSource{
    position: absolute  !important;
    bottom: 20px        !important;
}
.preLoaderBox{
    display: flex;
    justify-content: center;
}
.preloaderIcon{
    width: 80px      !important;
    height: 80px     !important;
}




/*  xs, extra-small: 0px            */
/*  sm, small: 600px                */
/*  md, medium: 900px               */
/*  lg, large: 1200px               */
/*  xl, extra-large: 1536px         */

/*WIDTH*/

/*XS*/
@media (min-width: 0px) {
    .paperNewsSection{
        height: auto;
    }
}

/*SM*/
@media (min-width: 600px) {

}

/*MD*/
@media (min-width: 900px) {
    .paperNewsSection{
        height: 650px;
    }
}

/*LG*/
@media (min-width: 1200px) {

}

/*XL*/
@media (min-width: 1536px) {

}
