.paperTimeFrame {
    border-radius: 20px;
    margin-bottom: 10px;
    height: 100px;
    max-width: 100%
}

.timeFrameTitle {
    text-align: center;
}

.timeFrameListContent {
    display: flex;
    justify-content: space-evenly;
    max-width: 100%;
    font-weight: bold;
}

.timeFrameBtn {
    border: 1px solid #e0f64b   !important;
    border-radius: 5px          !important;
    padding: 0                  !important;
    display: flex               !important;
    justify-content: center     !important;
}

.paperChart {
    border-radius: 20px;
    padding: 30px;
    max-height: 100%;
}


/*XS*/
@media (min-width: 0px) {
    .paperChart {
        height: auto;
    }
}

/*SM*/
@media (min-width: 600px) {

}

/*MD*/
@media (min-width: 900px) {
    .paperChart {
        height: 350px;
    }
}

/*LG*/
@media (min-width: 1200px) {
}

/*XL*/
@media (min-width: 1536px) {
}