.tableContainer {
    border-radius: 20px;
    height: 550px;
    max-width: 100%;
}

.tableContentSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tableCellCoinInfo {
    display: flex;
    align-items: center;
    gap: 15px;
}

.coinIcon {
    width: 60px !important;
    height: 60px !important;
}

.coinName {
    font-weight: bold;
    font-size: 20px;
}

.coinSymbol {
    text-transform: uppercase;
}

.coinPrice {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 5px
}

.tableBodyContentBox {
    display: flex;
    justify-content: center;
}

.label {
    font-weight: bold !important;
}

.selectedCoinValues {
    text-transform: uppercase;
    font-weight: bold !important;
}


/*XS*/
@media (min-width: 0px) {
    .tableContainer {
        width: 100% !important;
    }
}

/*SM*/
@media (min-width: 600px) {

}

/*MD*/
@media (min-width: 900px) {
    .tableContainer {
        width: 450px !important;
    }
}

/*LG*/
@media (min-width: 1200px) {
}

/*XL*/
@media (min-width: 1536px) {
}