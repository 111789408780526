.page_enter {
    opacity: 0;
    transform: scale(1.1);
}

.page_enter_active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 800ms, transform 800ms;
}

.page_exit {
    opacity: 1;
    transform: scale(1);
}

.page_exit_active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 800ms, transform 800ms;
}

