.container {
    height: 100vh !important;
    display: flex !important;
}

.gridContainer {
    flex: 1;
}

.gridItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    margin: 0 auto;
}


/*LOGIN INFO SECTION*/
.gridItemInfoSection {
    background-image: url("../../assets/images/login-img/login-background.svg") !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgba(255, 255, 255, 0.09) !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loginInfoSection {
    max-width: 560px;
}

.loginInfoTitle {
    color: #fff;
    margin-bottom: 20px;
    margin-top: 20px;
    font-weight: bold;
}

.loginInfoDescription {
    color: #fff;
}


/*XS*/
@media (min-width: 0px) {
    .container {
        margin-bottom: 40px;
    }

    .gridContainer {
        width: 100vw !important;
    }

    .gridItemInfoSection {
        display: none;
    }
}

/*SM*/
@media (min-width: 600px) {

}

/*MD*/
@media (min-width: 900px) {
}

/*LG*/
@media (min-width: 1200px) {
    .gridItemInfoSection {
        display: flex;
    }
}

/*XL*/
@media (min-width: 1536px) {
    .container {
        margin-bottom: 0px;
    }

    .gridContainer {
        width: 100%;
    }
}