.dialogCloseIcon {
    position: absolute !important;
    right: 8px;
    top: 8px;
}

/*SearchCoin*/
.paperSearchCoin {
    margin-top: 10px;
}

.searchDialogTitle {
    text-align: center;
    margin: 10px 0px 10px 0px;
}

.searchCoinInput {
    width: 100%;
}

.searchCoinMenu {
    color: #B8B8B8 !important;
    font-size: 12px !important;
    display: flex;
    justify-content: space-between !important;
}

.searchCoinContentBox {
    display: flex;
    align-items: center;
}

.searchCoinIcon {
    width: 30px !important;
    height: 30px !important;
    margin-right: 10px;
}

/*BuySellCoinsComponent*/
.selectedCoinContent {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.selectedCoinIcon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.selectedCoinName {
    font-weight: bold;
    margin-right: 5px;
}

.selectedCoinSymbol {
    color: #c0c0ce;
    fontSize: 10px;
}

.selectedCoinPurchaseContent {
    margin-top: 10px;
    display: flex;
    gap: 30px;
}

.paperSelectedCoin {
    background-color: rgba(255, 255, 255, 0.16);
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 20px;
}

.errorMessage {
    color: #24de19;
    font-weight: bold;
    margin-bottom: 10px
}

.buyingAmount {
    margin-top: 10px;
    font-weight: bold;
    font-size: 20px;
}

.purchaseName {
    display: flex ;
    justify-content: center;
    margin-top: 20px
}


