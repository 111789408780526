.coinBox {
    max-width: 230px;
    text-align: center;
    margin: 0 auto;
}

.coinBoxTitle {
    color: #fff;
    font-weight: bold;
    margin-top: 20px
}

.formContainer {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    gap: 20px;
    max-width: 300px;
    width: 100%
}

.errorMessage {
    color: red;
    text-align: center;
}

.accountText {
    color: #848484;
    text-align: center;
    margin: 0 auto;
    font-size: 14px;
}

.createAccountButton {
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    max-width: 100%;
}

.forgotPassword {
    color: #848484;
    text-align: center;
    margin: 0 auto;
    font-size: 14px;
}

