.signUpWrapper {
    display: flex;
    flex-direction: column;
    max-width: 350px
}

.createAccTitle {
    color: #fff;
    font-weight: bold;
    text-align: center;
    max-width: 250px;
    margin: 0 auto;
}

.signUpBoxContent {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 350px;
    width: 100%
}

.error {
    color: red;
    text-align: center;
}

.createPersonalAcc {
    margin-bottom: 70px;
}

.exitDialogTitle {
    color: #848484;
    text-align: center;
    max-width: 250px;
    margin: 0 auto !important;
    font-size: 14px;
}