.priceWidgetWrapper {
    margin-top: 30px;
    justify-content: space-evenly;
    gap: 5px;
    max-width: 100%
}

.paperPriceWidget {
    border-radius: 20px;
    padding: 30px;
    height: 170px;
    width: 200px;
    max-width: 100%
}

.priceInPercent {
    border-radius: 5px;
    padding: 5px;
    text-align: center;
    margin-top: 40px;
}

.priceNaming {
    text-align: center;
    margin-top: 10px;
}


/*XS*/
@media (min-width: 0px) {
    .priceWidgetWrapper {
        display: none;
        flex-wrap: wrap;
    }
}

/*SM*/
@media (min-width: 600px) {
}

/*MD*/
@media (min-width: 900px) {
}

/*LG*/
@media (min-width: 1200px) {
    .priceWidgetWrapper {
        display: flex;
        flex-wrap: nowrap;
    }
}

/*XL*/
@media (min-width: 1536px) {
}