.appBar {
    position: static !important;
    border-radius: 0px !important;
}

.container {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    max-width: 1500px !important
}

.containerDesktop {
    gap : 50px;
}


.logoAvatar {
    margin-right: 20px;
    width: 50px;
    height: 50px;
}

.logoTitle {
    font-weight: 600 !important;
    letter-spacing: .3rem;
    color: inherit;
    text-decoration: none;
}

.bottomNavContainer {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
}

.bottomNavigation {
    padding-top: 10px;
    width: 100%;
    height: 100px;
}

.bottomNavIconContainer {
    color: #fff;
    text-align: center;
}

.bottomNavIcon {
    fill: #fff;
    height: 20px;
    width: 20px;
}

.routesNaming {
    font-size: 15px;
}

.desktopHeaderRoutesContainer {
    flex-grow: 1;
    justify-content: space-between !important;
}

.menuNavLink{
    text-decoration: none;
    list-style-type: none;
    color: white;
    cursor: pointer
}
.desktopMenuRoutes{
    display: flex;
    gap: 10px;
    align-items: center;
}

/*XS*/
@media (min-width: 0px) {
    .toolbarContainer {
        display: flex;
        justify-content: space-between;
    }

    .logoTitle {
        display: none;
    }

    .desktopHeaderRoutesContainer {
        display: none;
    }
}

/*SM*/
@media (min-width: 600px) {

}

/*MD*/
@media (min-width: 900px) {
    .logoSection {
        display: flex;
        align-items: center;
    }

    .logoTitle {
        display: flex;
    }

    .desktopHeaderRoutesContainer {
        display: flex !important;
    }
}

/*LG*/
@media (min-width: 1200px) {
}

/*XL*/
@media (min-width: 1536px) {
}
