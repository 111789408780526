.paperFearGreed {
    border-radius: 20px;
    margin-bottom: 30px;
    height: auto;
    max-width: 100%;
}

.featGreedBoxContent{
    display: flex;
}

.fearGreedBoxInfo{
    display: flex;
    gap: 10px;
    align-items: center;
}
.fearGreedBoxInfo h6{
    font-weight: bold;
}

.styles.fearGreedAvatar{
    width: 30px!important;
    height: 30px!important;
}

.fearGreenDefinition{
    color: #c2baba;
    font-size: 12px
}

.fearGreenDataContent{
    display: flex;
    justify-content: center;
}

.fearGreenDataWrapper{
    position: relative;
}

.fearGreenChart{
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
    background-color: #0D1421;
    position: absolute;
    top: 81px;
    left: -5px;
}
.fearGreedBoxValue{
    text-align: center;
    /*position: absolute;*/
    /*top: 30%;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*left: 50%*/
}

.fearGreedValue{
    font-weight: bold;
    font-size: 22px
}

.lastUpdate{
    color: #c2baba;
    font-size: 13px;
    margin-top: 11px;
}

/*HISTORICAL VALUES*/

.fearGreedValues h6 {
    font-weight: bold;
    margin-bottom: 10px
}

.historicalValuesWrapper{
    display: flex;
    justify-content: space-between;
    gap: 30px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 5px;
    padding-bottom: 5px
}

.historicalDate{
    font-size: 14px;
    color: #ccc;
}

.historicalClassValue{
    font-weight: bold;
}
.currentGreedValue{
    padding: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}




/*XS*/
@media (min-width: 0px) {
    .featGreedBoxContent {
        justify-content: center;
    }
}

/*SM*/
@media (min-width: 600px) {
    .featGreedBoxContent {
        justify-content: space-around;
    }
}

/*MD*/
@media (min-width: 900px) {
}

/*LG*/
@media (min-width: 1200px) {

}

/*XL*/
@media (min-width: 1536px) {
}

@media (max-width: 600px) {
    .fearGreedValues {
        display: none;
    }
}
