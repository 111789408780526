.paperBalanceWrapper {
    border-radius: 20px;
    margin-bottom: 10px;
    background-image: url("../../assets/images/image/block_bg.svg") !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgba(255, 255, 255, 0.09)                     !important;
    max-width: 100%
}

.balanceWidgetGrid {
    position: relative;
}

.balanceWidgetGridItem {
    font-size: 15px;
    color: #B8B8B8;
}

.balanceWidgetSelect {
    margin-left: 10px;
    color: #B8B8B8;
    font-size: 12px
}

.balanceWidgetMenu {
    color: #B8B8B8;
    font-size: 12px;
}

.btcPrice{
    color: #B8B8B8;
    font-size: 12px;
}

.walletImage {
    position: absolute;
    bottom: -20px;
    right: 0;
    max-width: 140px;
    width: 100%;
    height: auto;
}

.walletImage img{
    width: 100%;
    height: auto;
}




@media (max-width: 440px) {
    .walletImage {
        display: none;
    }
}



