
.tableContainer {
    border-radius: 20px;
    height: 1000px;
    overflow-y: auto;
    cursor: pointer
}
.preloader {
    display: flex;
    justify-content: center;
    margin-top: 100px
}

.tableHeadRow{
    padding-top: 120px;
}
.tableFiltrationCell{
    background: none !important;
    border-bottom: none !important;
}
.filtrationBoxContent{
    display: flex;
    align-items: center;
    gap: 20px;
}

.tableStickyCoin {
    position: sticky;
    left: 0;
    width: 200px;
    background-color: rgb(31, 31, 31);
    borderRadius: 5px;
}

.filterByNameInput{
    max-width: 100%;
    width: 150px;
}

.searchFilterWrapper{
    display: flex;
    align-items: center;
    gap: 20px;
}

.currencyIcon{
    width: 30px!important;
    height: 30px!important;
}

.filterCurrencySelector{
    height: 40px !important;
}

.filterRowsCell{
    background: none !important;
    border-bottom: none !important;
}

.filterRowSelector{
    height: 40px!important;
}

.tableBodyContentBox{
    display: flex;
    flex-wrap : wrap;
    gap: 10px;
    align-items: center
}

.coinName{
    font-weight: bold;
}

.coinSymbol{
    text-transform: uppercase;
}







/*WIDTH*/

/*XS*/
@media (min-width: 0px) {
}

/*SM*/
@media (min-width: 600px) {

}

/*MD*/
@media (min-width: 900px) {
}

/*LG*/
@media (min-width: 1200px) {
}

/*XL*/
@media (min-width: 1536px) {
}
