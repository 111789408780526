.carouselSliderWrapper{
    border: 2px solid #333;
    border-radius: 12px ;
    margin-bottom : 20px
}

.paperCarouselSlider{
    height: 250px;
    max-width : 100%
}

.emojiIcon{
    width: 30px;
    height: 30px;
}

.sliderDataContentBox{
    display: flex;
    justify-content: space-between;
    cursor : pointer;
}

.sliderDataWrapper{
    display: flex;
    gap: 20px;
    margin-top: 5px
}

.sliderCoinIcon{
    width: 30px !important;
    height: 30px !important;
}
.sliderContentCoinAlign{
    display: flex;
}
.coinName{
    font-weight: bold;
}

.coinSymbol{
    font-size: 10px;
    color: #c0c0ce;
}
.priceChangeBox{
    display: flex;
    align-items: center;
}