
/*SKELETON COIN INFO*/
.skeletonChartTimeFrame {
    border-radius: 20px ;
    height : 100px !important;
    margin-bottom: 10px ;
    background-color: rgba(255, 255, 255, 0.05) !important;
}

.skeletonChart {
    border-radius: 20px ;
    height : 350px !important;
    padding: 30px;
    background-color: rgba(255, 255, 255, 0.05) !important;
}

.skeletonCoinTableDesc {
    border-radius: 20px ;
    width : 450px !important;
    max-width: 100%;
    height : 550px !important;
    padding: 30px;
    background-color: rgba(255, 255, 255, 0.05) !important;
}

.skeletonLinksWidget {
    border-radius: 20px ;
    margin-top: 20px;
    width : 450px !important;
    max-width: 100%;
    height : 120px !important;
    background-color: rgba(255, 255, 255, 0.05) !important;
}


.skeletonPriceWidgetData {
    border-radius: 20px ;
    background-color: rgba(255, 255, 255, 0.05) !important;
    /*padding: 30px;*/
    height:  170px !important;
    width: 200px;
    max-width: 100%;
}

.skeletonCryptoExplorers {
    border-radius: 20px ;
    background-color: rgba(255, 255, 255, 0.05) !important;
    padding: 30px;
    height:  1200px !important;
    width: 100%;
    max-width: 100%;
}