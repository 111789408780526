.profileContainer {
    margin-top: 100px;
    margin-bottom: 70px;
}

.paperProfile {
    max-width: 600px;
    width: 100%;
    position: relative;
    margin: 0 auto;
}

.profileBoxContent {
    display: flex;
    justify-content: center;
}

.profileWrapper {
    display: flex;
    gap: 20px;
    flex-direction: column;
    margin-top: 80px
}

.profileWrapper h5 {
    text-align: center;
}

.profileUserSection {
    max-width: 400px;
    width: 100%;
}

.profileUserSection button {
    margin-top: 20px !important;
    width: 100% !important;
}

.notification {
    margin-top: 20px;
    color: green;
}

/*ProfileAvatarUpload*/

.profileAvatarBoxContent {
    position: absolute;
    top: -50px;
    cursor: pointer;
}

.userAvatar{
    width: 130px !important;
    height: 130px !important;
    border: 1px solid #fff;
}

.addPhotoIcon{
    right: 0;
    bottom: 0;
    position: absolute !important;
}
.closeIconBtn{
    position: absolute !important;
    top: 8px;
    right: 8px;
}

.userAvatarSelectBox{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px
}
.uploadInputField{
    display: none;
}

.defaultAvatarSection{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-around;
}

.defaultAvatars   {
    width: 80px !important;
    height: 80px !important;
    border: 1px solid #fff;
}
.defaultAvatars:hover {
    background-color: #171717
}

.dialogsAvatarAction{
    display: flex;
    justify-content: center
}
.dialogsAvatarAction button{
    width: 100%;
}


/*XS*/
@media (min-width: 0px) {
    .profileBoxContent {
        padding: 10px;
    }
    .profileAvatarBoxContent {
        left: 35%;
    }
}

/*SM*/
@media (min-width: 600px) {
    .profileAvatarBoxContent {
        left: 40%;
    }
}

/*MD*/
@media (min-width: 900px) {
}

/*LG*/
@media (min-width: 1200px) {
}

/*XL*/
@media (min-width: 1536px) {
    .profileBoxContent {
        padding: 50px;
    }
}