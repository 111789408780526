.forgotPwBtn{
    max-width: 300px;
    width: 100%;
    margin-top : 5px;
}

.dialogContainer{
    text-align: center;
}

.closeIcon{
    position: absolute !important;
    right: 8px;
    top: 8px;
}

.dialogsActions{
    display: flex;
    justify-content: center;
}

